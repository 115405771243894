<template>
  <div
    :id="modalName"
    class="modal"
    role="dialog"
    aria-hidden="true"
    data-backdrop="static"
    data-keyboard="false"
  >
    <div class="modal-dialog modal-md modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Masukkan Nomor Registrasi</h5>
        </div>
        <div class="modal-body">
            <div class="form-group row">
              <label class="col-lg-4 col-form-label">{{ $t('common.nomorRegistrasi') }}:</label>
              <div class="col-lg-6">
                <input
                  type="text"
                  class="form-control"
                  v-model="nomorRegistrasi"
                  v-validate="'required'"
                  data-vv-as="Nomor Registrasi"
                  name="noRegistrasi"
                  :class="{'is-invalid': errors.has('noRegistrasi') }"
                  placeholder="No Registrasi "
                />
				<span
                  v-if="duplicateError"
				  style="fontSize:80%; color:#fd397a; marginTop:0.25rem; widht:100%; fontWeight:400;"
                >Nomor Registrasi Sudah Terdaftar</span>
                <div
                  v-show="errors.first('noRegistrasi')"
                  class="invalid-feedback"
                >{{ errors.first('noRegistrasi') }}</div>
              </div>
            </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-clean btn-bold btn-upper btn-font-md"
            @click="clearData()"
          >{{ $t("button.close") }}</button>
          <button
            type="button"
            class="btn btn-default btn-bold btn-upper btn-font-md"
            v-on:click="submitData()"
          >{{ $t("button.save") }}</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SweetAlertTemplate from "./../../_general/SweetAlert";
import BlockUI from "../../_js/BlockUI";
const blockUI = new BlockUI();
import { RepositoryFactory } from "./../../../repositories/RepositoryFactory";
const ReservationRepository = RepositoryFactory.get("reservation");
const TransaksiReferralRepository = RepositoryFactory.get("transaksiReferral");
import Jejak from "./../../../model/jejak-model.js"
const SweetAlert = Vue.extend(SweetAlertTemplate);

export default {
  data() {
    return {
      id: null,
      nomorRegistrasi: null,
      transaksiReferral: null,
	  duplicateError: false,
    };
  },
  watch: {
	  nomorRegistrasi(val){
		  if(!this.val){
			  this.duplicateError = false
		  }
	  }
  },
  props: {
    modalName: { type: String , default:'ModalNomorRegistrasi'},
    modalData: { type: Object }
  },
  methods: {
    clearData: function () {
      $("#" + this.modalName).modal("hide");
      this.$emit("showModalRegistrasi", false);
    },
    submitData: function(){
        var vx = this;
        this.$validator.validateAll().then(result => {
            if (result) {
                let instance = new SweetAlert().showConfirm({
                  swalTitle: "Apakah Anda yakin?",
                  swalText: "Tekan ok untuk mengubah jejak menjadi " + vx.$i18n.t("EnumStage.PASIEN_DATANG"),
                  swalType: "info",
                  onConfirmButton: function () {
                    let jejak = new Jejak();
                    jejak.enumStage = "PASIEN_DATANG";
                    jejak.transaksiId = vx.transaksiReferral.id;
                    jejak.timestamp = moment().local().format('YYYY-MM-DDTHH:mm:ss');
                    jejak.nomorRegistrasi = vx.nomorRegistrasi
                    blockUI.blockPage();
                    vx.submit(jejak)
                  },
                  onCancelButton: function () {
                    blockUI.unblockModal();
                  }
                })
            }
        })
    },
    async submit(jejak){
      var vx = this;
      await TransaksiReferralRepository.addJejak(jejak)
        .then(res => {
          new Promise((resolve, reject) => {
            vx.$parent.onInit(vx.transaksiReferral.id, resolve);
          }).then((response) => {
            let instance = new SweetAlert().showInfo({
              swalTitle: "Berhasil!",
              swalText: "Perubahan Jejak berhasil disimpan.",
              swalType: "success",
              onConfirmButton: function () {
                blockUI.unblockPage();
                vx.clearData()
              }
            });
          });
        })
        .catch(err => {
          let error = err.response.data
          var message = (error.status == 400 && error.violations[0].field == 'nomor_registrasi') ?
          "Nomor registrasi "+this.nomorRegistrasi+" sudah terdaftar"
          :"Tidak dapat mengubah perubahan data / status."
          let instance = new SweetAlert().showInfo({
            swalTitle: "Gagal!",
            swalText: message,
            swalType: "error",
            onConfirmButton: function () {
				vx.duplicateError = true
              // vx.nomorRegistrasi = null
              blockUI.unblockPage();
            }
          });
        })
    },
  },
  mounted() {
    $("#" + this.modalName).modal();
    if (this.modalData) {
      this.transaksiReferral = this.modalData
      this.nomorRegistrasi = this.transaksiReferral.nomorRegistrasi
    }
  }
};
</script>
